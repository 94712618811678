<template>
  <div>
    <p class="mb-6">Movimiento por Productos</p>

    <v-card>
      <v-card-subtitle>Filtros</v-card-subtitle>
      <v-card-text>
        <v-form ref="form" lazy-validation>
          <v-row>
            <v-col cols="12" sm="6" md="4">
              <DosFechasVue @getFechas="getFechas" :fechas='[filtros.desde, filtros.hasta]' />
            </v-col>
            <v-col cols="12" sm="6" md="4"  >
              <select-puesto
                :rules="reglas.requerido"
                 v-model="filtros.id_puesto"
                :add="false"
                :dense="true"
                :outlined="true"
                :clearable="true"
                :ind_principal="true"
                :storePuesto="true" 
              ></select-puesto>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <SelectAlmacen
             
                :outlined="true"
                :clearable="true"
                :dense="true"
                v-model="filtros.id_almacen" 
              :filtro="{ id_puesto: filtros.id_puesto } "
                :add="false"
              />
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <BuscarProductoVue :rules="reglas.requerido" @GetProducto="GetProducto" />
            </v-col>

            <v-col cols="12" md="12" class="text-right">
              <v-btn small :loading="cargando" :disabled="cargando" color="primary" @click="buscarDatos()">
                Buscar
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-subtitle>Moviientos detallados</v-card-subtitle>
      <v-card-text>
        <TablaSimpleReporte
          :nombreReporte="nombreReporte"
          :cabeceraIn="cabecera"
          :datosIn="datos"
          :exportar="['EXCEL', 'PDF']"
          :orientacionReporte="'l'"
        ></TablaSimpleReporte>
      </v-card-text>

   
    </v-card>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiPoll, mdiLabelVariantOutline, mdiCurrencyUsd, mdiHelpCircleOutline } from '@mdi/js'
import TablaSimpleReporte from '@/components/TablaSimpleReporte.vue'
import { onBeforeMount, ref } from '@vue/composition-api'
import SelectPuesto from '@/views/sistema/administracion/archivo/puesto/components/SelectPuesto2.vue' //'../../../archivo/puesto/components/SelectPuesto.vue';
import ProductoService from '@/api/servicios/ProductosService'
import moment from 'moment'
import store from '@/store'
import SelectCategoria from '../../maestro/categoria/componentes/Select.vue'
import SelectSubCatoria from '@/views/sistema/inventario/maestro/sub-categoria/componentes/Select.vue'
import SelectAlmacen from '@/views/sistema/inventario/maestro/almacen/componentes/Select.vue'
import SelectProductoTipo from '@/views/sistema/inventario/maestro/producto-tipo/componentes/Select.vue'

import DosFechasVue from '@/components/DosFechas.vue'
import BuscarProductoVue from '../../producto/componentes/BuscarProducto.vue'
// demos

export default {
  watch: {
    id_puesto: function (old, news) {
      if (old != news) {
        this.filtros.id_puesto = old
      }
    },
  },
  props: {
    id_puesto: {
      type: String,
      default: -1,
    },
  },
  components: {
    TablaSimpleReporte,
    SelectPuesto,
    SelectCategoria,
    SelectSubCatoria,
    SelectAlmacen,
    SelectProductoTipo,
    DosFechasVue,
    BuscarProductoVue,
  },
  watch: {
    'filtros.producto': function (news, old) {
      this.filtros.id_producto = news.id
    }, 
    'filtros.id_puesto': function (news, old) {
      if (old != news) {
        this.filtros.id_almacen = null
      }
    },

    id_puesto: function (news, old) {
      if (old != news) {
        this.filtros.id_puesto = news
      }
    },
  },
  setup(props) {
    const nombreReporte = ref(`Movimientos de inventario`)
    const itemsSelect = [
      {
        id: 0,
        text: 'TODOS',
      },
      {
        id: 1,
        text: 'SOLO SIN RECETAS',
      },
      {
        id: 2,
        text: 'SOLO CON RECETAS',
      },
    ]
    const fcondicional = dato => {
      if (dato > 0) {
        return 'success--text'
      } else if (dato < 0) {
        return 'error--text'
      } else {
        return 'secondary--text'
      }
    }
    onBeforeMount(() => {
      if (props.id_puesto != -1) {
        filtros.value.id_puesto = props.id_puesto
      }
    })
    const color = dato => {
      return 'secondary--text'
    }
    const form = ref(null)
    const reglas = {
      requerido: [v => !!v || v == 0 || 'Es requerido'],
    }
    const cabecera = ref([
      { text: 'Fecha', value: 'fecha', format: { small: true, dateTime:true }  },
      { text: 'Area', value: 'nombre_puesto', format: { small: true } }, 
      { text: 'Almacen', value: 'descripcion_almacen', format: { small: true } }, 
      { text: 'Id Documento', value: 'id_documento', format: { small: true } }, 
      { text: 'Tipo Movimiento', value: 'descripcion_tipo_movimiento', format: { small: true } }, 
      { text: 'Producto', value: 'nombre_producto', format: { small: true } }, 
      { text: 'entrada', value: 'entrada', format: { small: true, class: 'success--text' } },
      { text: 'salida', value: 'salida', format: { small: true, class: 'error--text' } },
      { text: 'Existencia', value: 'existencia', format: { small: true, color: { condicional: fcondicional } } },
    ])
 

    const datos = ref({
      agrupado_almacen: [],
      agrupado_tipo_transaccion: [],
      movimientos: [],
    })
    const filtros = ref({
      id_puesto: null,
      id_almacen: null,
      id_producto: '',
      producto: {},
      puesto: {},
      desde: moment().format('YYYY-MM-DD'),
      hasta: moment().format('YYYY-MM-DD'),
    })
    const productoSelect = ref(null)
    const GetProducto = dato => {
      productoSelect.value = dato
      filtros.value.id_producto = dato.id
      console.log('GetProducto', dato)
    }
    const cargando = ref(false)

    const buscarDatos = () => {
      if (validarForm()) {
        cargando.value = true
        ProductoService.reporteMovimientoProductoExistencia({...filtros.value, })
          .then(response => {
            if (response.data.mensaje == 'BUSQUEDA_EXITOSA') {
              datos.value = response.data.datos
              nombreReporte.value = `Movimientos y existencia / ${productoSelect.value.nombre.toLowerCase()}  / ${
                filtros.value.desde
              } ${filtros.value.hasta}`

              if (datos.value.length == 0) {
                store.commit('setAlert', {
                  message: 'No hubo resultado a la busqueda',
                  type: 'info',
                })
              }
            } else {
              store.commit('setAlert', {
              message: response.data.mensaje,
              type: 'waring',
            })
            }
          })
          .catch(err => {})
          .finally(() => {
            cargando.value = false
          })
      }
    }
    const getFechas = datos => {
      filtros.value.desde = datos.desde
      filtros.value.hasta = datos.hasta
    }

    const validarForm = () => {
      let val = form.value?.validate()
      return val
    }
    return {
      cabecera,
      datos,
      buscarDatos,
      cargando,
      nombreReporte,
      filtros,
      itemsSelect,
      getFechas,
      GetProducto,
      form,
      reglas
    }
  },
}
</script>
